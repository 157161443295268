<template>
  <div>
    <div v-if="$router.currentRoute.name === 'promotions-adhocpromotion'" style="position: relative" class="promotions-adhocpromotion">
      <h1 class="text-xs-center">Send Promotional Credit</h1>
      <br />
      <div class="px-2" style="position: absolute; top: 25px; left: 0; width: 100%;"><v-progress-linear indeterminate :active="apiInProgress" height="4px" /></div>
      <v-form v-if="promotion && !promotionError" @submit.prevent="send()" ref="form" class="mx-2">
        <div class="layout justify-end">
          <router-link :to="{ name: 'promotions' }"><v-btn color="warning mr-2" flat :disabled="apiInProgress">Cancel</v-btn></router-link>
           <v-btn color="primary" type="submit" :disabled="apiInProgress || !isDataEdited()" :loading="apiInProgress"><v-icon small>fal fa-hand-holding-usd</v-icon> &nbsp; Send</v-btn>
        </div>
        <div class="layout row wrap justify-start align-start">
          <div style="width: 60%">
            <v-layout row wrap mb-1>
              <v-flex class="text-xs-right font-weight-bold" sm4 pr-3>
                Valid promotion to take credit from
              </v-flex>
              <v-flex sm8>
                <v-autocomplete
                  v-model="promotion.selectedPromotion"
                  :items="validPromotions"
                  :loading="validPromotionsApiInProgress"
                  :search-input.sync="promotionSearchKeywords"
                  :rules="[v => v ? true : 'Please select a promotion']"
                  outline
                  clearable
                  hide-selected
                  item-text="customName"
                  item-value="id"
                  label="Selected promotion"
                  placeholder="Search by promotion custom name"
                  return-object
                >
                  <template slot="item" slot-scope="{ item }">
                    <div style="width: 100%">
                      <div>{{item.customName}}</div>
                      <div><em><small>Spent / Budget: {{item.budgetSpentLabel}} out of {{item.budgetLabel}}</small></em></div>
                      <div v-if="item.datesValidLabel"><em><small>Valid date(s): {{item.datesValidLabel}}</small></em></div>
                      <hr style="width: 100%; border: 0; border-bottom: 1px solid #ccc" />
                    </div>
                  </template>
                  <template slot="selection" slot-scope="{ item }">
                    <div>
                      <div>{{item.customName}}</div>
                      <div><em><small>Spent / Budget: {{item.budgetSpentLabel}} out of {{item.budgetLabel}}</small></em></div>
                    </div>
                  </template>
                </v-autocomplete>
              </v-flex>
            </v-layout>
            <v-layout row wrap mb-1>
              <v-flex class="text-xs-right font-weight-bold" sm4 mt-3 pr-3>
                Send credit to this passenger
              </v-flex>
              <v-flex sm8>
                <passenger-selector v-model="promotion.selectedPassenger" :initial-search-keyword="initialPassengerPhone" :child-props="{ placeholder: 'Search by phone number or name', label: 'Selected passenger', outline: true }" />
              </v-flex>
            </v-layout>
            <v-layout row wrap mb-1>
              <v-flex class="text-xs-right font-weight-bold" sm4 mt-3 pr-3>
                Credit amount
              </v-flex>
              <v-flex sm8>
                <currency-field v-model="promotion.amount" :child-props="{ placeholder: 'Enter amount to credit' }" />
              </v-flex>
            </v-layout>
            <v-layout row wrap mb-1>
              <v-flex class="text-xs-right font-weight-bold" sm4 mt-3 pr-3>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon small v-on="on">fa fa-info-circle</v-icon>
                  </template>
                  <span>Let the passenger know they received their credit (via SMS text)</span>
                </v-tooltip> Enable/Disable confirmation
              </v-flex>
              <v-flex sm8 class="layout row justify-start align-baseline">
                <v-switch v-model="promotion.sendPromotionMessage" label="Send confirmation message to passenger" />
              </v-flex>
            </v-layout>
            <v-layout row wrap mb-1 v-show="promotion.sendPromotionMessage">
              <v-flex class="text-xs-right font-weight-bold" sm4 mt-3 pr-3>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon small v-on="on">fa fa-info-circle</v-icon>
                  </template>
                  <span>In addition to the standard confirmation message,<br />include a message specifically for this passenger</span>
                </v-tooltip>
                Additional text for confirmation message
              </v-flex>
              <v-flex sm8>
                <div class="additional-message-container">
                  <v-textarea
                    auto-grow
                    counter="105"
                    style="padding: 15px;"
                    placeholder="Add optional additional message"
                    v-model="promotion.additionalPromotionalMessage"
                    :rules="[v => !v || v.length <= 105 ? true : 'Please reduce the length to only 105 characters']"
                  ></v-textarea>
                  <div class="grey--text px-3 pb-3">
                    Enjoy your credit of {{promotion.amount || '&lt;no amount specified yet&gt;'}}!
                    Transaction ID: &lt;ID of purchased gift&gt;
                  </div>
                </div>
              </v-flex>
            </v-layout>
            <v-layout row wrap mb-1>
              <v-flex class="text-xs-right font-weight-bold" sm4 mt-3 pr-3>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon small v-on="on">fa fa-info-circle</v-icon>
                  </template>
                  <span>Provide a specific reason on why the credit was given</span>
                </v-tooltip>
                 Notes
              </v-flex>
              <v-flex sm8>
                <v-textarea
                  outline
                  auto-grow
                  :rules="[v => v ? true : 'Please enter a short reason for this credit']"
                  placeholder="Enter reason(s) why you sent this credit"
                  v-model="promotion.staffNote"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </div>
        </div>
        <div class="layout justify-end">
          <router-link :to="{ name: 'promotions' }"><v-btn color="warning" class="ml-2" flat :disabled="apiInProgress">Cancel</v-btn></router-link>
           <v-btn color="primary" type="submit" class="ml-2" :disabled="apiInProgress || !isDataEdited()" :loading="apiInProgress"><v-icon small>fal fa-hand-holding-usd</v-icon> &nbsp; Send</v-btn>
        </div>
      </v-form>
      <v-alert :value="promotionError" type="error">
        <p>{{promotionError}}</p>
        <p><a href @click.stop="refreshView()">Retry</a> or <router-link :to="{ name: 'promotions' }">Return to promotions list</router-link></p>
      </v-alert>
    </div>
  </div>
</template>

<style scoped>
 .additional-message-container {
   border: 2px solid rgba(0,0,0,0.54);
   margin-bottom: 20px;
   border-radius: 4px;
 }
</style>

<script>
import src from './src'
export default src
</script>
